/*
 * Ally Zernick
 * 2023-10-20
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import {
  CREATE_INSTITUTION_OR_CAMPUS,
  DEVELOPER,
  VIEW_AND_EDIT_INSTITUTION_OR_CAMPUS,
} from '../../Globals';
import {
  createInstitution,
  createInstitutionCampus,
  createPartnerAgreement,
  fetchInstitutions,
  fetchPartnerAgreementTypes,
  fetchPartnerAgreements,
  getAllCampuses,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InstitutionCampuses from './InstitutionCampuses';
import Institutions from './Institutions';
import provinces from 'provinces';
import {
  stringSimilarity,
} from 'string-similarity-js';
import styles from './styles';

const PartnerAgreements = () => {
  const [_institutionCampusId, setInstitutionCampusId] = useState();
  const [_partnerAgreementTypeId, setPartnerAgreementTypeId] = useState();
  const [_institutionId, setInstitutionId] = useState(null);
  const [_institutionName, setInstitutionName] = useState(null);
  const [_campusName, setCampusName] = useState(null);
  const [_url, setURL] = useState(null);
  const [_city, setCity] = useState(null);
  const [_state, setState] = useState(null);
  const [_isCampusInternational, setIsCampusInternational] = useState(false);
  const [_campusType, setCampusType] = useState(2);
  const [_notes, setNotes] = useState(null);
  const admin = useSelector((state) => state.admin);
  const allCampuses = useSelector((state) => state.allCampuses);
  const institutions = useSelector((state) => state.institutions);
  const partnerAgreementTypes = useSelector((state) => state.partnerAgreementTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartnerAgreements());
    dispatch(getAllCampuses());
    dispatch(fetchPartnerAgreementTypes());
    dispatch(fetchInstitutions());
  }, []);

  const _resetVars = () => {
    setInstitutionCampusId(null);
    setPartnerAgreementTypeId('2');
    setCity(null);
    setState(null);
    setURL(null);
    setIsCampusInternational(false);
    setCampusType(2);
    setCampusName(null);
    setInstitutionName(null);
    setNotes(null);
  };

  const concatenatedCampusInfo = `${_campusName || ''} ${_city || ''} ${_state || ''}`.trim();

  const similarCampuses = concatenatedCampusInfo ?
    allCampuses.filter((campus) => {
      const campusInfo = `${campus.name} ${campus.city} ${campus.state}`.trim();
      return stringSimilarity(campusInfo, concatenatedCampusInfo) >= 1.0;
    }) : [];

  const error = similarCampuses.length > 0;
  const helperText = similarCampuses.map((campus) => `${campus.name} - ${campus.city}- ${campus.state} already exists.`).join(' ');

  return (
    <div>
      {(admin.perms[CREATE_INSTITUTION_OR_CAMPUS] || admin.perms[DEVELOPER]) &&
        <>
          <div>
            <h2>Create New Institution</h2>
            <p>Please confirm that the institution does not exist already prior to creating a new institution.</p>
            <div style={styles.schoolContainer}>
              <TextField
                label='Institution Name'
                variant='outlined'
                style={styles.campusInput}
                error={_institutionName && institutions.filter((i) => stringSimilarity(i.name, _institutionName) >= 0.8).length > 0}
                helperText={_institutionName && institutions.filter((i) => stringSimilarity(i.name, _institutionName) >= 0.8).map((i) => {
                  return `${i.name} (${i.id}) already exists. `;
                })}
                value={_institutionName === null ? '' : _institutionName}
                onChange={(e) => setInstitutionName(e.target.value)}
              />
              <Button
                variant='contained'
                color='primary'
                disabled={_institutionName === null || institutions.filter((i) => stringSimilarity(i.name, _institutionName) >= 0.8).length > 0}
                onClick={() => {
                  dispatch(createInstitution(_institutionName));
                  _resetVars();
                }}
                style={styles.bottomSpacing}
              >
                Create
              </Button>
            </div>
            <hr width='100%' style={styles.verticalSpacing} />
            <h2>Create Institution Campus</h2>
            <p>Please select an institution, enter a campus name, a url, a city, a state (if applicable), a campus type, and if the campus in international. </p>
            <FormControl style={styles.campusInput} variant='outlined'>
              {institutions.length > 0 &&
                <Autocomplete
                  id='combo-box-demo'
                  options={institutions.map((a) => ({
                    id: a.id,
                    name: a.name,
                  }))}
                  getOptionLabel={(institution) => institution.name}
                  onChange={(e, institution) => setInstitutionId(institution.id)}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='*Institution'
                      variant='outlined'
                    />}
                />
              }
            </FormControl>
            <p>Campus name formatting guide:</p>
            <ul>
              <li style={styles.bottomSpacing}>Single campus: Institution name (i.e, Geneva College)</li>
              <li>Multiple named campuses: Institution name - Campus name (i.e, Bethel University - Paris & Bethel University - Clarksville)</li>
            </ul>
            <div style={styles.campusContainer}>
              <TextField
                label='*Campus Name'
                variant='outlined'
                style={styles.campusInput}
                value={_campusName === null ? '' : _campusName}
                onChange={(e) => setCampusName(e.target.value)}
              />
              <p>{`Please enter the URL specific to the campus you're entering.`}</p>
              <TextField
                label='*URL'
                variant='outlined'
                style={styles.campusInput}
                value={_url === null ? '' : _url}
                onChange={(e) => setURL(e.target.value)}
              />
              <TextField
                label='*City'
                variant='outlined'
                style={styles.campusInput}
                value={_city === null ? '' : _city}
                onChange={(e) => setCity(e.target.value)}
              />
              <FormControl style={styles.campusInput}>
                <InputLabel>State</InputLabel>
                <Select
                  label='State'
                  onChange={(e) => setState(e.target.value)}
                  variant='outlined'
                  value={_state === null ? '' : _state}
                >
                  {provinces.filter((value) => value.country === 'US' || value.country === 'CA').map((state) => {
                    return <MenuItem key={state.short} value={state.short}>{state.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <TextField
                label='Notes'
                variant='outlined'
                style={styles.campusInput}
                value={_notes === null ? '' : _notes}
                onChange={(e) => setNotes(e.target.value)}
              />
              {error && (
                <>
                  <hr />
                  <div style={styles.errorText}>{helperText}</div>
                </>
              )}
            </div>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>*Campus Type</FormLabel>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue={'2'}
                onChange={(e) => setCampusType(e.target.value)}
              >
                <FormControlLabel
                  value={'1'}
                  control={<Radio color='primary' />}
                  label='Secondary Education'
                />
                <FormControlLabel
                  value={'2'}
                  control={<Radio color='primary' />}
                  label='Higher Education'
                />
              </RadioGroup>
            </FormControl>
            <div style={styles.campusContainer}>
              <FormControl>
                <FormControlLabel
                  onChange={() => setIsCampusInternational(!_isCampusInternational)}
                  control={<Checkbox />}
                  label='Campus is international'
                />
              </FormControl>
              <Button
                variant='contained'
                color='primary'
                disabled={!_institutionId || !_campusName || !_url || !_city}
                style={styles.createCampusButton}
                onClick={() => {
                  dispatch(createInstitutionCampus({
                    institution_id: _institutionId,
                    name: _campusName,
                    url: _url,
                    city: _city,
                    state: _state,
                    international: _isCampusInternational ? 1 : 0,
                    disabled: 0,
                    institution_campus_type_id: _campusType,
                    notes: _notes,
                  }));
                  _resetVars();
                }}
              >
                Create Campus
              </Button>
            </div>
          </div>
          <hr width='100%' style={styles.verticalSpacing} />
          <h2>Create a New Partner Agreement</h2>
          <div style={{...styles.schoolContainer, ...styles.largeBottomSpacing}}>
            <FormControl style={styles.quarterWidth} variant='outlined'>
              {allCampuses.length > 0 &&
                <Autocomplete
                  id='combo-box-demo'
                  options={allCampuses.map((a) => ({
                    id: a.id,
                    name: a.name,
                    city: a.city,
                  }))}
                  getOptionLabel={(campus) => campus.name + ' - ' + campus.city}
                  onChange={(e, campus) => setInstitutionCampusId(campus?.id)}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='Institution Campus'
                      variant='outlined'
                    />}
                />
              }
            </FormControl>
            <FormControl style={styles.quarterWidth} variant='outlined'>
              {partnerAgreementTypes.length > 0 &&
                <Autocomplete
                  id='combo-box-demo'
                  options={partnerAgreementTypes.map((a) => ({
                    id: a.id,
                    name: a.name,
                  }))}
                  getOptionLabel={(campus) => campus.name}
                  onChange={(e, campus) => setPartnerAgreementTypeId(campus?.id)}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='Partner Agreement Type'
                      variant='outlined'
                    />}
                />
              }
            </FormControl>
            <Button
              color='primary'
              variant='contained'
              disabled={_institutionCampusId === undefined || _partnerAgreementTypeId === undefined}
              onClick={() => {
                dispatch(createPartnerAgreement(_institutionCampusId, _partnerAgreementTypeId, () => dispatch(fetchPartnerAgreements())));
                _resetVars();
              }}
            >
              Create Partner Agreement
            </Button>
          </div>
          <hr width='100%' style={styles.verticalSpacing} />
        </>
      }
      {(admin.perms[VIEW_AND_EDIT_INSTITUTION_OR_CAMPUS] || admin.perms[DEVELOPER]) &&
        <>
          {allCampuses.length > 0 && institutions.length > 0 &&
            <InstitutionCampuses
              institutionCampuses={allCampuses}
              institutions={Object.values(institutions)}
            />
          }
          <hr width='100%' style={styles.verticalSpacing} />
          {institutions.length > 0 &&
            <Institutions
              institutions={Object.values(institutions)}
            />
          }
        </>
      }
    </div>
  );
};

export default (PartnerAgreements);
