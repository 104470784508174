/*
 * Stevie Michalik
 * 2024-05-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import {
  createInstructorSection,
  fetchBlueprintCoursesByCourseCode,
  fetchBlueprintMigrations,
  fetchCourses,
  fetchInstructors,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import dayjs from 'dayjs';
import styles from './styles.js';

const SectionCreation = () => {
  const instructors = useSelector((state) => Object.values(state.instructors).filter((instructor) => instructor.support === 0 && instructor.disabled === 0));
  const blueprints = useSelector((state) => state.blueprints);
  const courses = useSelector((state) => state.courses);
  const fetches = useSelector((state) => state.fetches);
  const [_blueprintMigrations, setBlueprintMigrations] = useState(0);
  const [_courseCode, setCourseCode] = useState('biod151');
  const [_blueprint, setBlueprint] = useState('');
  const [_instructor, setInstructor] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstructors());
    dispatch(fetchCourses());
    dispatch(fetchBlueprintMigrations((result) => setBlueprintMigrations(result)));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBlueprintCoursesByCourseCode(_courseCode));
  }, [dispatch, _courseCode]);

  useEffect(() => {
    const filteredBlueprints = Object.values(blueprints).filter((blueprint) => {
      return (blueprint.course_code === _courseCode);
    });
    if (filteredBlueprints.length > 0) {
      const sortedBlueprints = filteredBlueprints.sort((b1, b2) => {
        return dayjs(b2.created_at).format('YYYY-MM-DD').date - dayjs(b1.created_at).format('YYYY-MM-DD').date;
      });
      setBlueprint(sortedBlueprints[sortedBlueprints.length - 1].canvas_course_id);
    }
  }, [dispatch, fetches]);

  useEffect(() => {
    if (!_blueprintMigrations) {
      const interval = setInterval(() => {
        dispatch(fetchBlueprintMigrations((result) => setBlueprintMigrations(result)));
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [dispatch, _blueprintMigrations]);

  const _returnSelectCourseOptions = () => {
    const filteredCourses = Object.values(courses).filter((course) => {
      return (course.disabled === 0 && !course.code.includes('-'));
    });

    const sortedCourses = filteredCourses.sort((c1, c2) => {
      return (c1.code > c2.code) ? 1 : (c1.code < c2.code) ? -1 : 0;
    });

    return sortedCourses.map((course) => {
      return <MenuItem key={course.id} value={course.code}>{course.code}</MenuItem>;
    });
  };

  const _returnSelectBlueprintOptions = () => {
    const filteredBlueprints = Object.values(blueprints).filter((blueprint) => {
      return (blueprint.course_code === _courseCode);
    });

    const sortedBlueprints = filteredBlueprints.sort((b1, b2) => {
      return (b1.canvas_course_id > b2.canvas_course_id) ? 1 : (b1.canvas_course_id < b2.canvas_course_id) ? -1 : 0;
    });
    
    return sortedBlueprints.map((blueprint) => {
      return <MenuItem key={blueprint.canvas_course_id} value={blueprint.canvas_course_id}>{blueprint.name}</MenuItem>;
    });
  };

  return (
    <div style={styles.container}>
      <Paper style={styles.paper} elevation={8}>
        <div>
          <h2>Create new Section for Faculty Members</h2>
          <p>This feature is designed to create a brand new section for a faculty member based on a blueprint</p>
          <p>Use when adding a faculty member to a course they have never taught before, or when adding a faculty member to a new blueprint</p>
          <p>The feature will default to select the Blueprint that has been most recently created.</p>
        </div>
        {(!_blueprintMigrations &&
            <div>
              <p><b>Please be patient.</b> Verifying all Blueprint Migrations are completed.</p>
              <p>This is conducting checks regularly - Migrations usually take one minute to complete...</p>
              <div>
                <CircularProgress />
              </div>
            </div>) ||
          <div>
            <div style={styles.formControl}>
              <FormControl sx={{width: 'fit-content'}}>
                <InputLabel id='course-code'>Course Code</InputLabel>
                <Select
                  defaultValue={_courseCode}
                  htmlFor='course-code'
                  label='Course Code'
                  onChange={(e) => setCourseCode(e.target.value)}
                >
                  {_returnSelectCourseOptions()}
                </Select>
              </FormControl>
              <FormControl sx={{width: 'fit-content'}}>
                <InputLabel id='blueprint'>Blueprint</InputLabel>
                <Select
                  defaultValue={_blueprint}
                  key={_blueprint}
                  htmlFor='blueprint'
                  label='Blueprint'
                  onChange={(e) => {
                    setBlueprint(e.target.value);
                  }}
                >
                  {_returnSelectBlueprintOptions()}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='instructor'>Instructor</InputLabel>
                <Select
                  style={{width: 'fit-content', minWidth: '300px'}}
                  defaultValue={_instructor}
                  htmlFor='instructor'
                  label='Instructor'
                  onChange={(e) => {
                    setInstructor(e.target.value);
                  }}
                >
                  {instructors.length > 0 && Object.values(instructors).sort((i1, i2) => {
                    return (i1.first_name > i2.first_name) ? 1 : (i1.first_name < i2.first_name) ? -1 : 0;
                  }).map((instructor) => {
                    return <MenuItem key={instructor.id} value={instructor.id}>{instructor.first_name} {instructor.last_name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                onClick={() => {
                  setBlueprintMigrations(0);
                  dispatch(createInstructorSection(_courseCode, _blueprint, _instructor));
                }}
                disabled={!_instructor || !_blueprint || !_courseCode}
                variant='contained'
                color='primary'
              >
                Create Instructor Section
              </Button>
            </div>
          </div>
        }
      </Paper>
    </div>
  );
};

export default SectionCreation;
