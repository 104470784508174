/*
 * Stevie Michalik
 * 2024-11-14
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  tableCellHidden: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  responseBox: {
    margin: '4px',
    padding: '10px',
  },
  responsesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
  editSchool: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editSchoolField: {
    minWidth: '215px',
  },
};

export default styles;
