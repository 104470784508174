/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {
  fetchDepartments,
  fetchInstructorAssignmentRules,
  updateInstructorAssignmentRules,
} from '../../actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import styles from './styles';

class InstructorAssignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      year: new Date().getFullYear(),
      divideByDepartment: false,
      edit: false,
    };
  }

  componentDidMount() {
    this.props.fetchDepartments();
    this.props.fetchInstructorAssignmentRules(this.state.year);
  }

  _refreshAssignmentRules = () => {
    this.props.fetchInstructorAssignmentRules(this.state.year);
  };

  _enrollmentsTable = (instructorAssignmentRules, depId, currentYear) => {
    const thisMonth = new Date().getMonth();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      .filter((month, index) => !currentYear || index <= thisMonth + 1);
    return <Table>
      <TableHead>
        <TableRow>
          <TableCell>Instructor</TableCell>
          {months.map((month) => <TableCell key={month}>{month}</TableCell>)}
          <TableCell>YTD Totals</TableCell>
          {currentYear &&
            <>
              <TableCell>Status</TableCell>
              {this.state.edit &&
                <TableCell>Update Targets</TableCell>
              }
            </>
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {instructorAssignmentRules.map((rule) => {
          let uncapped = false;
          const monthlyMult = rule.month_multiplier;
          const yearlyTarget = monthlyMult > 1;
          let targets = rule.total_targets;
          let multipleDepartmentsWarning = false;
          if (currentYear) {
            if (this.state.divideByDepartment) {
              if (Object.keys(rule.targets_by_department).includes(depId)) {
                targets = rule.targets_by_department[depId];
              } else {
                multipleDepartmentsWarning = true;
              }
            } else if (Object.keys(rule.targets_by_department).length > 1) {
              multipleDepartmentsWarning = true;
            }
            uncapped = targets.max < 0;
          }

          return <TableRow key={`${rule.first_name} ${rule.last_name}`}>
            <TableCell>{`${rule.first_name} ${rule.last_name}`}</TableCell>
            {
              rule.totals.filter((month, index) => !currentYear || index <= thisMonth + 1)
                .map((month, index) => <TableCell key={index}>
                  {month[0]}<br/>
                  {month[3]}<br/>
                  {month[4]}<br/>
                  <strong>{month[0] + month[3] + month[4]}</strong>
                </TableCell>)
            }
            <TableCell>
              {rule.totals.reduce((a, b) => a + b[0], 0)}<br/>
              {rule.totals.reduce((a, b) => a + b[3], 0)}<br/>
              {rule.totals.reduce((a, b) => a + b[4], 0)}<br/>
              <strong>{rule.totals.reduce((a, b) => a + b[0] + b[3] + b[4], 0)}</strong>
            </TableCell>
            {currentYear &&
              (multipleDepartmentsWarning
                ? <>
                  <TableCell style={styles.editTargetsError}>
                    This multi-department instructor has {this.state.divideByDepartment ? 'only one total target.' : 'multiple targets.'}<br/>
                    Turn {this.state.divideByDepartment ? 'off' : 'on'} Divide by Department to edit their targets.
                  </TableCell>
                  <TableCell/>
                </>
                : <>
                  {yearlyTarget
                    ? (uncapped
                        ? <TableCell>
                          Full-time YTD Total: <strong>{targets.current}</strong><br/>
                          Monthly Target: <strong>{targets.min}</strong><br/>
                          Full-time YTD Target: {monthlyMult} x {targets.min} + {targets.overflow} = <strong>{targets.min * monthlyMult + targets.overflow}</strong>
                        </TableCell>
                        : <TableCell>
                          Full-time Capped YTD Total: <strong>{targets.current}</strong><br/>
                          Monthly Min: <strong>{targets.min}</strong><br/>
                          Monthly Max: <strong>{targets.max}</strong><br/>
                          Full-time Capped YTD Target: {monthlyMult} x {targets.max} + {targets.overflow} = <strong>{targets.max * monthlyMult + targets.overflow}</strong>
                        </TableCell>
                    )
                    : <TableCell>
                      Monthly Total: <strong>{targets.current}</strong><br/>
                      Monthly Min: {targets.min} + {targets.overflow} = <strong>{targets.min + targets.overflow}</strong><br/>
                      Monthly Max: {targets.max} + {targets.overflow} = <strong>{targets.max + targets.overflow}</strong>
                    </TableCell>
                  }
                  {this.state.edit &&
                    <TableCell>
                      <TextField
                        type='number'
                        label={'Monthly Minimum'}
                        defaultValue={targets.min}
                        sx={{marginTop: '7px'}}
                        onBlur={(e) => {
                          const monthlyMin = Number(e.target.value);
                          if (monthlyMin >= 0 && Number.isInteger(monthlyMin) &&
                            monthlyMin !== targets.min && (uncapped || monthlyMin <= targets.max)) {
                            this.props.updateInstructorAssignmentRules(rule.id, {monthly_min: monthlyMin},
                              this.state.divideByDepartment ? depId : null, this._refreshAssignmentRules);
                          }
                        }}
                      /><br/>
                      {uncapped ||
                        <TextField
                          type='number'
                          label={'Monthly Maximum'}
                          defaultValue={targets.max}
                          sx={{marginTop: '7px'}}
                          onBlur={(e) => {
                            const monthlyMax = Number(e.target.value);
                            if (monthlyMax >= 0 && Number.isInteger(monthlyMax) &&
                              monthlyMax !== targets.max && monthlyMax >= targets.min) {
                              this.props.updateInstructorAssignmentRules(rule.id, {monthly_max: monthlyMax},
                                this.state.divideByDepartment ? depId : null, this._refreshAssignmentRules);
                            }
                          }}
                        />
                      }
                      <br/>
                      <TextField
                        type='number'
                        label={'Overflow Value'}
                        defaultValue={targets.overflow}
                        sx={{marginTop: '7px'}}
                        onBlur={(e) => {
                          const overflow = Number(e.target.value);
                          if (Number.isInteger(overflow) && overflow !== targets.overflow) {
                            this.props.updateInstructorAssignmentRules(rule.id, {overflow: overflow},
                              this.state.divideByDepartment ? depId : null, this._refreshAssignmentRules);
                          }
                        }}
                      />
                    </TableCell>
                  }
                </>
              )
            }
          </TableRow>;
        })}
      </TableBody>
    </Table>;
  };

  render() {
    if (!('instructorAssignmentRules' in this.props && Array.isArray(this.props.instructorAssignmentRules) &&
        'departments' in this.props && Array.isArray(this.props.departments))) {
      return <h1><CircularProgress/></h1>;
    }
    const currentYear = new Date().getFullYear();
    let year = 2010;
    const yearOptions = [];
    while (year <= currentYear + 1) {
      yearOptions.push(year);
      year++;
    }
    const instructorsByDepartment = {};
    const departmentNames = {};
    const inCurrentPayYear = this.props.instructorAssignmentRules.length > 0 && Object.keys(this.props.instructorAssignmentRules[0]).includes('total_targets');
    this.props.instructorAssignmentRules.sort((a, b) => (
      (inCurrentPayYear ? +(b.total_targets.max < 0 || b.month_multiplier > 1) - +(a.total_targets.max < 0 || a.month_multiplier > 1) : 0) // sort uncapped instructors to top
      || (a.last_name + a.first_name < b.last_name + b.first_name ? -1 : 1) // sort by last, first name
    )).forEach((rule) => {
      Object.keys(rule.totals_by_department).forEach((departmentID) => {
        if (!instructorsByDepartment[departmentID]) {
          instructorsByDepartment[departmentID] = [];
          const departmentName = this.props.departments.find((e) => e.id === parseInt(departmentID)).name;
          departmentNames[departmentID] = `${departmentName[0].toUpperCase()}${departmentName.slice(1)}`;
        }
        if (this.state.divideByDepartment) {
          instructorsByDepartment[departmentID].push({...rule, totals: rule.totals_by_department[departmentID]});
        } else {
          instructorsByDepartment[departmentID].push(rule);
        }
      });
    });
    return (
      <div style={styles.container}>
        <div style={styles.rangeSelection}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => {
                    this.setState({divideByDepartment: e.target.checked});
                  }}
                />}
              label='Divide by Department'
            />
          </FormControl>
          <FormControl>
            <Select
              defaultValue={currentYear}
              onChange={(e) => {
                this.props.fetchInstructorAssignmentRules(e.target.value);
                this.setState({year: e.target.value});
              }}
            >
              {yearOptions.map((year) => <MenuItem key={year} value={year}>{year}</MenuItem>)}
            </Select>
            <FormHelperText>Select year</FormHelperText>
          </FormControl>
          {inCurrentPayYear &&
            <Button
              style={styles.button}
              variant='contained'
              color='primary'
              onClick={() => this.setState({edit: !this.state.edit})}
            >
              Edit Targets
            </Button>
          }
        </div>
        <Accordion style={styles.panel}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <h2 style={styles.graphTitle}>All Departments</h2>
          </AccordionSummary>
          <AccordionDetails style={styles.panelDetails}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Department</TableCell>
                  <TableCell>Jan</TableCell>
                  <TableCell>Feb</TableCell>
                  <TableCell>Mar</TableCell>
                  <TableCell>Apr</TableCell>
                  <TableCell>May</TableCell>
                  <TableCell>Jun</TableCell>
                  <TableCell>Jul</TableCell>
                  <TableCell>Aug</TableCell>
                  <TableCell>Sep</TableCell>
                  <TableCell>Oct</TableCell>
                  <TableCell>Nov</TableCell>
                  <TableCell>Dec</TableCell>
                  <TableCell>YTD Total</TableCell>
                  {inCurrentPayYear &&
                    <TableCell>Status</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(departmentNames).sort((a, b) => departmentNames[a] < departmentNames[b] ? -1 : 1)
                  .map((departmentId) =>
                    <TableRow key={departmentId}>
                      <TableCell>{departmentNames[departmentId]}</TableCell>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) =>
                        <TableCell key={month}>
                          {
                            instructorsByDepartment[departmentId].reduce((current, rule) =>
                              rule.totals_by_department[departmentId][month][0] +
                              rule.totals_by_department[departmentId][month][3] +
                              rule.totals_by_department[departmentId][month][4] + current, 0)
                          }
                        </TableCell>)}
                      <TableCell>
                        {instructorsByDepartment[departmentId].reduce(
                          (current, rule) => rule.totals_by_department[departmentId].reduce(
                            (a, b) => a + b[0] + b[3] + b[4], 0) + current, 0)
                        }
                      </TableCell>
                      {inCurrentPayYear &&
                        <TableCell>
                          Monthly Target: <strong>{
                            instructorsByDepartment[departmentId].reduce(
                              (current, rule) => {
                                if (Object.keys(rule.targets_by_department).includes(departmentId)) {
                                  return rule.targets_by_department[departmentId].min + current;
                                } else if (Object.keys(rule.targets_by_department).includes(0)) {
                                  return rule.total_targets.min + current;
                                } else {
                                  return current;
                                }
                              }, 0
                            )}
                          </strong><br/>
                          Monthly Capacity: <strong>{
                            instructorsByDepartment[departmentId].reduce(
                              (current, rule) => {
                                let targets;
                                if (Object.keys(rule.targets_by_department).includes(departmentId)) {
                                  targets = rule.targets_by_department[departmentId];
                                } else if (Object.keys(rule.targets_by_department).includes(0)) {
                                  targets = rule.total_targets;
                                } else {
                                  return current;
                                }
                                const max = targets.max === -1 ? targets.min : targets.max;
                                return max + current;
                              }, 0)}
                          </strong>
                        </TableCell>
                      }
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
        {Object.keys(departmentNames).sort((a, b) => departmentNames[a] < departmentNames[b] ? -1 : 1)
          .map((departmentId) =>
            <Accordion key={departmentId} style={styles.panel}>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <h2 style={styles.graphTitle}>{departmentNames[departmentId]}</h2>
              </AccordionSummary>
              <AccordionDetails style={styles.panelDetails}>
                {this._enrollmentsTable(instructorsByDepartment[departmentId], departmentId, inCurrentPayYear)}
              </AccordionDetails>
            </Accordion>
          )}
        <div>
          <h3>Legend:</h3>
          <p>Portage Prep<br/>3 Credits<br/>4 Credits<br/><strong>Total</strong></p>
          <p>Instructors with a hard monthly cap on enrollments are marked with a star (*).</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    departments: state.departments,
    instructorAssignmentRules: state.instructorAssignmentRules,
    loadingStates: state.loadingStates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchDepartments,
    fetchInstructorAssignmentRules,
    updateInstructorAssignmentRules,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InstructorAssignment);
