/*
 * Ally Zernick
 * 2023-05-30
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import React, {
  useEffect, useState,
} from 'react';
import {
  fetchAdmins,
  fetchLowGradeWarnings,
  patchLowGradeWarning,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import HasViolation from '../HasViolation';
import dayjs from 'dayjs';
import styles from './styles';

const LowGradeWarnings = () => {
  const [_rowsPerPage, setRowsPerPage] = useState(5);
  const [_pageNum, setPageNum] = useState(0);
  const [_displayActiveStudents, setDisplayActiveStudents] = useState(false);
  const [_note, setNote] = useState();
  const [_warning, setWarning] = useState();
  const [_warningId, setWarningId] = useState(0);
  const [_successTeamFilter, setSuccessTeamFilter] = useState(-1);
  const [_successTeamId, setSuccessTeamId] = useState(-1);
  const [_searchTerm, setSearchTerm] = useState('');
  const warnings = useSelector((state) => Object.values(state.lowGradeWarnings));
  const admins = useSelector((state) => state.admins);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdmins());
    dispatch(fetchLowGradeWarnings());
  }, [dispatch]);

  const _loadGrades = (objectAssignments, objectNeededGrades) => {
    const grades = Object.values(objectNeededGrades).map((key) => [key]);

    return (
      <div>
        <p style={styles.detailsRow}><b>Average grades needed:</b></p>
        {grades.map((grade) => {
          return (
            <p style={styles.detailsRow} key={grade.grade}><b>{grade[0].grade}:</b>{grade[0].grade_needed}</p>
          );
        })}
      </div>
    );
  };

  const _renderWarnings = () => {
    let filteredWarnings = warnings;

    if (warnings.length > 0) {
      const searchTerms = _searchTerm.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));

      if (_displayActiveStudents) {
        filteredWarnings = warnings.filter((warning) => {
          return warning.status === 'active';
        });
      }

      filteredWarnings = filteredWarnings.filter((warning) => {
        if (_successTeamFilter === -1) {
          return warning.success_team_member_id === null;
        }
        return warning.success_team_member_id === _successTeamFilter;
      });

      filteredWarnings = filteredWarnings.filter((warning) => {
        if (!_searchTerm) {
          return true;
        }

        return searchTerms.some((groupOfSearchTerms) => {
          return groupOfSearchTerms.every((searchTerm) => {
            return Object.keys(warning).some((warningAttribute) => {
              return `${warning[warningAttribute]}`.toLowerCase().includes(searchTerm);
            });
          });
        });
      });

      filteredWarnings = Object.values(filteredWarnings).sort((a, b) => new Date(b.latest_grade) - new Date(a.latest_grade));
    }

    const emptyRows = _rowsPerPage - Math.min(_rowsPerPage, filteredWarnings.length - _pageNum * _rowsPerPage);
    return (
      <>
        <Table
          id='lowGradeWarnings'
          aria-labelledby='tableTitle'
          size='medium'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Student Id</TableCell>
              <TableCell align='left'>Student Name</TableCell>
              <TableCell align='left'>Student Email</TableCell>
              <TableCell align='left'>Enrollment Id</TableCell>
              <TableCell align='left'>Initial Grade</TableCell>
              <TableCell align='left'>Current Grade</TableCell>
              <TableCell align='left'>Average Needed for a C</TableCell>
              <TableCell align='left'>Completion Percentage</TableCell>
              <TableCell align='left'>More Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWarnings.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((warning) => {
              return (
                <React.Fragment key={warning.id}>
                  <TableRow
                    hover
                    style={warning.inactive ? {backgroundColor: '#ffcbd1'} : {backgroundColor: '#FFFFFF'}}
                  >
                    <TableCell align='left'>{warning.student_id}</TableCell>
                    <TableCell align='left'>{warning.student_name}</TableCell>
                    <TableCell align='left'>{warning.student_email}</TableCell>
                    <TableCell align='left'>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={`/students/${warning.student_id}`}
                        style={{display: 'flex', alignItems: 'center'}}
                      >
                        {warning.enrollment_id}
                        {!!warning.has_integrity_violation && <HasViolation severity={warning.has_integrity_violation} />}
                      </a>
                    </TableCell>
                    <TableCell align='left'>{warning.initial_grade}</TableCell>
                    <TableCell align='left'>{warning.current_grade}</TableCell>
                    <TableCell align='left'>{`${warning.needed_grades[7].grade_needed}%`}</TableCell>
                    <TableCell align='left'>{warning.completion_percentage}</TableCell>
                    <TableCell align='left'>
                      <Radio
                        checked={_warningId === warning.id}
                        name='warningSelect'
                        value={warning.id}
                        onChange={() => {
                          setWarning(warning);
                          setWarningId(warning.id);
                          setNote(warning.notes);
                          setSuccessTeamId(warning.success_team_member_id ? warning.success_team_member_id : -1);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{height: 75 * emptyRows}}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component='div'
          count={filteredWarnings.length}
          rowsPerPage={_rowsPerPage}
          page={_pageNum}
          slotProps={{
            previousButton: {'aria-label': 'Previous Page'},
            nextButton: {'aria-label': 'Next Page'},
          }}
          onPageChange={(event, _pageNum) => setPageNum(_pageNum)}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        />
      </>
    );
  };

  return (
    <div>
      <h3>Low Grade Warnings</h3>
      <p>Please select more details to view other information, add notes, and disable warnings.
        Rows highlighted in red indicate the student has been inactive for over 4 weeks.</p>
      <div style={styles.row}>
        <div style={styles.searchBox}>
          <TextField
            onChange={(e) => setSearchTerm(e.target.value)}
            label='Search'
            margin='dense'
            variant='outlined'
          />
        </div>
        <div style={styles.filters}>
          <InputLabel>Display active warnings only</InputLabel>
          <Switch
            checked={_displayActiveStudents}
            onChange={() => setDisplayActiveStudents(!_displayActiveStudents)}
          />
        </div>
        <div style={styles.filters}>
          <InputLabel style={{marginRight: 10}}>Filter by assigned to:</InputLabel>
          <Select
            style={{marginTop: '10px'}}
            value={_successTeamFilter}
            onChange={(e) => {
              setSuccessTeamFilter(e.target.value);
              setPageNum(0);
            }}
          >
            <MenuItem value={-1}>Unassigned students</MenuItem>
            {Object.values(admins).filter((admin) => admin.department === 'Student Services' && (admin.id === 11 || admin.id === 63 || admin.id === 32)).map((admin) => {
              return <MenuItem key={admin.id} value={admin.id}>{admin.first_name} {admin.last_name}</MenuItem>;
            })}
          </Select>
        </div>
      </div>
      <Paper>
        {_renderWarnings()}
      </Paper>
      {_warning &&
        <div>
          <div style={styles.lineSeparator}></div>
          <h2>Selected student: {_warning.student_name} (Id: {_warning.student_id})</h2>
          <p><b>Course:</b>{` ${_warning.course}`}</p>
          <p><b>Instructor:</b>{` ${_warning.instructor}`}</p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Initial Grade</TableCell>
                <TableCell align='left'>Initial Grade Date</TableCell>
                <TableCell align='left'>Current Grade</TableCell>
                <TableCell align='left'>Current Percent Completed</TableCell>
                <TableCell align='left'>Latest Grade Date</TableCell>
                <TableCell align='left'>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align='left'>{_warning.initial_grade}</TableCell>
                <TableCell align='left'>{dayjs(_warning.created_at).format('YYYY-MM-DD')}</TableCell>
                <TableCell align='left'>{_warning.current_grade}</TableCell>
                <TableCell align='left'>{_warning.completion_percentage}</TableCell>
                <TableCell align='left'>{dayjs(_warning.latest_grade).format('YYYY-MM-DD')}</TableCell>
                <TableCell align='left'>{_warning.status}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div style={styles.row}>
            {_loadGrades(_warning.grades, _warning.needed_grades)}
            <div style={styles.multiColumn}>
              <div style={styles.column}>
                <InputLabel>Assigned to:</InputLabel>
                <Select
                  value={_successTeamId}
                  onChange={(e) => {
                    setSuccessTeamId(e.target.value);
                    dispatch(patchLowGradeWarning(_warning.id, {success_team_member_id: e.target.value}));
                  }}
                >
                  <MenuItem value={-1}>Unassigned</MenuItem>
                  {Object.values(admins).filter((admin) => admin.department === 'Student Services' && (admin.id === 11 || admin.id === 63 || admin.id === 32)).map((admin) => {
                    return <MenuItem key={admin.id} value={admin.id}>{admin.first_name} {admin.last_name}</MenuItem>;
                  })}
                </Select>
              </div>
              <div style={styles.column}>
                <h4>Notes:</h4>
                <TextField
                  multiline
                  minRows={4}
                  value={_note === null ? '' : _note}
                  variant='outlined'
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
                <Button
                  style={styles.buttonStyle}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch(patchLowGradeWarning(_warning.id, {notes: _note}));
                  }}
                >
                  Add/Update Notes
                </Button>
              </div>
            </div>
            <div style={styles.column}>
              <p>Warning: Disabling a warning will cause them to be removed from this report.</p>
              <Button
                style={styles.buttonStyle}
                variant='contained'
                color='primary'
                onClick={() => {
                  dispatch(patchLowGradeWarning(_warning.id, {disabled: 1}));
                  setWarning();
                }}
              >
                Disable Warning
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default (LowGradeWarnings);
