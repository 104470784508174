/*
 * Ryan O'Dowd
 * 2019-08-07
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  EDIT_STUDENT_INFO,
  REGISTRATION_REPORTS,
} from '../../Globals';
import {
  fetchMasterInstructorSheet,
  fetchMasterStudentSheet,
  fetchRegistrationCounts,
  fetchRegistrationData,
  fetchRegistrations,
} from '../../actions';
import {
  AdapterDayjs,
} from '@mui/x-date-pickers/AdapterDayjs';
import EditStudentDialog from '../StudentManagement/EditStudentDialog';
import Highlighter from 'react-highlight-words';
import PencilIcon from 'mdi-react/PencilIcon';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import dayjs from 'dayjs';
import styles from './styles';

class Registrations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNum: 0,
      rowsPerPage: 50,

      searchText: '',
      txtDump: '',

      enrollmentDateStarted: null,
      enrollmentDateEnded: null,
      enrollmentCompletionStartDate: null,
      enrollmentCompletionEndDate: null,
      students: 'all',
      registrationDataFor: 'both',

      studentIdToEdit: null,
      isEditStudentOpen: false,
      registrationDateStart: null,
      registrationDateEnd: null,
    };
  }

  componentDidMount() {
    this.props.fetchRegistrations();
    this.props.fetchRegistrationCounts();
    this.props.fetchRegistrationData();
  }

  _renderDatePicker(datePickerName) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={this.state[datePickerName]}
          format='MM/DD/YYYY'
          onChange={(e) => {
            this.setState({[datePickerName]: e});
          }}
        />
      </LocalizationProvider>
    );
  }

  _renderRegistrationDataRow(year, months) {
    return (
      <TableRow key={year}>
        <TableCell>{year}</TableCell>
        <TableCell>{!months.jan ? '-' : months.jan}</TableCell>
        <TableCell>{!months.feb ? '-' : months.feb}</TableCell>
        <TableCell>{!months.mar ? '-' : months.mar}</TableCell>
        <TableCell>{isNaN(months.jan + months.feb + months.mar) ? 0 : months.jan + months.feb + months.mar}</TableCell>
        <TableCell>{!months.apr ? '-' : months.apr}</TableCell>
        <TableCell>{!months.may ? '-' : months.may}</TableCell>
        <TableCell>{!months.jun ? '-' : months.jun}</TableCell>
        <TableCell>{isNaN(months.apr + months.may + months.jun) ? '-' : months.apr + months.may + months.jun}</TableCell>
        <TableCell>{!months.jul ? '-' : months.jul}</TableCell>
        <TableCell>{!months.aug ? '-' : months.aug}</TableCell>
        <TableCell>{!months.sep ? '-' : months.sep}</TableCell>
        <TableCell>{isNaN(months.jul + months.aug + months.sep) ? '-' : months.jul + months.aug + months.sep}</TableCell>
        <TableCell>{!months.oct ? '-' : months.oct}</TableCell>
        <TableCell>{!months.nov ? '-' : months.nov}</TableCell>
        <TableCell>{!months.dec ? '-' : months.dec}</TableCell>
        <TableCell>{isNaN(months.oct + months.nov + months.dec) ? '-' : months.oct + months.nov + months.dec}</TableCell>
        <TableCell>{isNaN(Object.values(months).reduce((acc, currentValue) => acc + currentValue, 0)) ? '-' : Object.values(months).reduce((acc, currentValue) => acc + currentValue, 0)}</TableCell>
      </TableRow>
    );
  }

  _sumPortageAndNabc() {
    return Object.entries(this.props.nabcRegistrationData).map(([year, months]) => {
      return [year, {
        jan: months.jan + this.props.portageRegistrationData[year].jan,
        feb: months.feb + this.props.portageRegistrationData[year].feb,
        mar: months.mar + this.props.portageRegistrationData[year].mar,
        apr: months.apr + this.props.portageRegistrationData[year].apr,
        may: months.may + this.props.portageRegistrationData[year].may,
        jun: months.jun + this.props.portageRegistrationData[year].jun,
        jul: months.jul + this.props.portageRegistrationData[year].jul,
        aug: months.aug + this.props.portageRegistrationData[year].aug,
        sep: months.sep + this.props.portageRegistrationData[year].sep,
        oct: months.oct + this.props.portageRegistrationData[year].oct,
        nov: months.nov + this.props.portageRegistrationData[year].nov,
        dec: months.dec + this.props.portageRegistrationData[year].dec,
      },
      ];
    });
  }

  render() {
    const searchTerms = this.state.searchText.split(' ').map((text) => text.toLowerCase());
    const filteredRegistrations = this.props.registrations.filter((registration) => {
      return searchTerms.every((searchTerm) => {
        return Object.keys(registration).some((registrationAttribute) => {
          return `${registration[registrationAttribute]}`.toLowerCase().includes(searchTerm);
        });
      });
    });
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, filteredRegistrations.length - this.state.pageNum * this.state.rowsPerPage);

    return (
      <div style={styles.container}>
        <Alert severity='warning'>This page is currently not supported. All features can be broken and data may not be accurate at any given time.</Alert>
        <div>
          {this.props.registrationCounts.last_month &&
            <>
              <p style={styles.countsText}>{`Last month's total: ${this.props.registrationCounts.last_month}`}</p>
              <p style={styles.countsText}>{`This month's total: ${this.props.registrationCounts.this_month}`}</p>
            </>
          }
        </div>
        <h3>Registrations</h3>
        <Paper>
          <Toolbar style={styles.tableToolbar}>
            <div style={styles.leftItems}>
              <TextField
                style={styles.textField}
                label='Search'
                value={this.state.searchText}
                onChange={(e) => this.setState({searchText: e.target.value})}
                margin='dense'
              />
            </div>
          </Toolbar>
          <div>
            <Table aria-labelledby='tableTitle'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>Date registered</TableCell>
                  <TableCell align='right'>Student name</TableCell>
                  <TableCell align='right'>Course name</TableCell>
                  <TableCell align='right'>School name</TableCell>
                  <TableCell align='right'>School city</TableCell>
                  <TableCell align='right'>School state</TableCell>
                  <TableCell align='right'>Program</TableCell>
                  <TableCell align='right'>Referred by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRegistrations.slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map((registration) => {
                  return (
                    (<React.Fragment key={registration.id}>
                      <TableRow
                        hover
                        tabIndex={-1}
                      >
                        <TableCell align='right'>
                          {dayjs(registration.created_at).format('ddd M/D, LT')}
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={`${registration.first_name} ${registration.last_name}`}
                          />
                          {!!this.props.admin.perms[EDIT_STUDENT_INFO] &&
                              <IconButton
                                color='secondary'
                                onClick={() => this.setState({studentIdToEdit: registration.student_id, isEditStudentOpen: true})}
                                size='large'
                              >
                                <PencilIcon />
                              </IconButton>
                          }
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={registration.course_name}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={registration.name}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={registration.city}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={registration.state}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={registration.program}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={registration.referred_by}
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>)
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>{/* @TODO: magic number */}
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={filteredRegistrations.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.pageNum}
            slotProps={{
              previousButton: {'aria-label': 'Previous Page'},
              nextButton: {'aria-label': 'Next Page'},
            }}
            onPageChange={(event, pageNum) => this.setState({pageNum})}
            onRowsPerPageChange={(event) => this.setState({rowsPerPage: event.target.value})}
          />
        </Paper>
        {!!this.props.admin.perms[REGISTRATION_REPORTS] && (
          <div style={styles.reportsContainer}>
            <div style={styles.cardWrapper}>
              <Card>
                <CardContent>
                  <h3>Master Grade Sheet</h3>
                  <div style={styles.formRow}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'>Type Of Students</FormLabel>
                      <RadioGroup
                        row
                        aria-label='position'
                        name='position'
                        defaultValue='all'
                        onChange={(e) => this.setState({students: e.target.value})}
                      >
                        <FormControlLabel
                          value='all'
                          control={<Radio color='primary' />}
                          label='All students'
                        />
                        <FormControlLabel
                          value='completed'
                          control={<Radio color='primary' />}
                          label='Complete Only'
                        />
                        <FormControlLabel
                          value='incomplete'
                          control={<Radio color='primary' />}
                          label='Incomplete Only'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div style={styles.formRow}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'>Start Range:</FormLabel>
                      <div style={styles.datePickerRow}>
                        {this._renderDatePicker('enrollmentDateStarted')}
                        <div style={styles.spacer}></div>
                        {this._renderDatePicker('enrollmentDateEnded')}
                      </div>
                    </FormControl>
                  </div>
                  <div style={styles.formRow}>
                    <FormControl>
                      <FormLabel component='legend'>Completed Range:</FormLabel>
                      <div style={styles.datePickerRow}>
                        {this._renderDatePicker('enrollmentCompletionStartDate')}
                        <div style={styles.spacer}></div>
                        {this._renderDatePicker('enrollmentCompletionEndDate')}
                      </div>
                    </FormControl>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={this.props.loadingStates.fetchMasterInstructorSheet || ((!this.state.enrollmentDateStarted || !this.state.enrollmentDateEnded) && (!this.state.enrollmentCompletionStartDate || !this.state.enrollmentCompletionEndDate))}
                    onClick={() => {
                      const convertToStringFormat = (dateObject) => {
                        if (dateObject) {
                          return dateObject.format('L');
                        }
                        return dateObject;
                      };

                      this.props.fetchMasterInstructorSheet(
                        convertToStringFormat(this.state.enrollmentDateStarted),
                        convertToStringFormat(this.state.enrollmentDateEnded),
                        convertToStringFormat(this.state.enrollmentCompletionStartDate),
                        convertToStringFormat(this.state.enrollmentCompletionEndDate),
                        this.state.students,
                      );
                      this.setState({
                        enrollmentDateStarted: null, enrollmentDateEnded: null, enrollmentCompletionStartDate: null, enrollmentCompletionEndDate: null,
                      });
                    }}
                  >Export</Button>
                  {this.props.loadingStates.fetchMasterInstructorSheet && <CircularProgress />}
                </CardActions>
              </Card>
            </div>
            <div style={styles.cardWrapper}>
              <Card>
                <CardContent>
                  <h3>Student Master List</h3>
                  <div style={styles.formRow}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'>Registration Date:</FormLabel>
                      <div style={styles.datePickerRow}>
                        {this._renderDatePicker('registrationDateStart')}
                        <div style={styles.spacer}></div>
                        {this._renderDatePicker('registrationDateEnd')}
                      </div>
                    </FormControl>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={this.props.loadingStates.fetchMasterStudentSheet || !this.state.registrationDateStart || !this.state.registrationDateEnd}
                    onClick={() => {
                      const convertToStringFormat = (dateObject) => {
                        if (dateObject) {
                          return dateObject.format('L');
                        }
                        return dateObject;
                      };

                      this.props.fetchMasterStudentSheet(
                        convertToStringFormat(this.state.registrationDateStart),
                        convertToStringFormat(this.state.registrationDateEnd),
                      );
                      this.setState({registrationDateStart: null, registrationDateEnd: null});
                    }}
                  >Export</Button>
                  {this.props.loadingStates.fetchMasterStudentSheet && <CircularProgress />}
                </CardActions>
              </Card>
            </div>
            <div style={styles.registrationCardWrapper}>
              <Card>
                <CardContent>
                  <h3>Registration Report</h3>
                  {this.props.loadingStates.fetchRegistrationData ? (
                    <div style={styles.loadingWrapper}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div style={styles.formWrapper}>
                      <FormControl component='fieldset'>
                        <FormLabel component='legend'>Division</FormLabel>
                        <RadioGroup
                          row
                          aria-label='position'
                          name='position'
                          defaultValue='both'
                          onChange={(e) => this.setState({registrationDataFor: e.target.value})}
                        >
                          <FormControlLabel
                            value='both'
                            control={<Radio color='primary' />}
                            label='Both'
                          />
                          <FormControlLabel
                            value='portage'
                            control={<Radio color='primary' />}
                            label='Portage Only'
                          />
                          <FormControlLabel
                            value='nabc'
                            control={<Radio color='primary' />}
                            label='NursingABC Only'
                          />
                        </RadioGroup>
                      </FormControl>
                      <div style={styles.tableWrapper}>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell>Year</TableCell>
                              <TableCell>Jan</TableCell>
                              <TableCell>Feb</TableCell>
                              <TableCell>Mar</TableCell>
                              <TableCell>Q1</TableCell>
                              <TableCell>Apr</TableCell>
                              <TableCell>May</TableCell>
                              <TableCell>Jun</TableCell>
                              <TableCell>Q2</TableCell>
                              <TableCell>Jul</TableCell>
                              <TableCell>Aug</TableCell>
                              <TableCell>Sep</TableCell>
                              <TableCell>Q3</TableCell>
                              <TableCell>Oct</TableCell>
                              <TableCell>Nov</TableCell>
                              <TableCell>Dec</TableCell>
                              <TableCell>Q4</TableCell>
                              <TableCell>Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(this.state.registrationDataFor === 'both' ? this._sumPortageAndNabc() : Object.entries(this.props[`${this.state.registrationDataFor}RegistrationData`])).map(([year, months]) => this._renderRegistrationDataRow(year, months))}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
        )}
        {this.state.studentIdToEdit && <EditStudentDialog studentId={this.state.studentIdToEdit} onClose={() => this.setState({studentIdToEdit: null})} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrations: Object.values(state.registrations).sort((a, b) => +b.id - (+a.id)),
    registrationCounts: state.registrationCounts,
    admin: state.admin,
    loadingStates: state.loadingStates,
    nabcRegistrationData: state.nabcRegistrationData,
    portageRegistrationData: state.portageRegistrationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchMasterInstructorSheet,
    fetchRegistrationCounts,
    fetchRegistrationData,
    fetchRegistrations,
    fetchMasterStudentSheet,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Registrations);
