/*
 * Ryan O'Dowd
 * 2021-05-24
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import {
  addNewSection,
  createAdmin,
  createInstructor,
  createPartnerAgreementObserver,
  fetchDepartments,
  fetchInstructors,
  fetchObserverPartnerAgreements,
  getTableColumnNames,
  insertDatabaseRecord,
  updateDatabaseRecord,
  updateInstructor,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReorderIcon from '@mui/icons-material/Reorder';
import styles from './styles';

const DatabaseTools = () => {
  const [_tableName, setTableName] = useState('enrollments');
  const [_columnName, setColumnName] = useState('');
  const [_columnValue, setColumnValue] = useState('');
  const [_recordId, setRecordId] = useState('');
  const [_canvasCourseId, setCanvasCourseId] = useState('');
  const [_insertValues, setInsertValues] = useState('');
  const [_isNullValue, setIsNullValue] = useState(false);
  const [_isColumnCurrentTimestamp, setIsColumnCurrentTimestamp] = useState(false);
  const [_isColumnValueRequired, setIsColumnValueRequired] = useState(true);
  const [_selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [_institutionAgreement, setInstitutionAgreement] = useState(null);
  const [_observerFirstName, setObserverFirstName] = useState(null);
  const [_observerLastName, setObserverLastName] = useState(null);
  const [_observerEmail, setObserverEmail] = useState(null);
  const [_instructorId, setInstructorId] = useState('');
  const [_instructorTitle, setInstructorTitle] = useState('');
  const [_instructorFirstName, setInstructorFirstName] = useState('');
  const [_instructorMiddleName, setInstructorMiddleName] = useState('');
  const [_instructorLastName, setInstructorLastName] = useState('');
  const [_instructorSuffix, setInstructorSuffix] = useState('');
  const [_instructorDisplayName, setInstructorDisplayName] = useState('');
  const [_instructorEmail, setInstructorEmail] = useState('');
  const [_instructorBio, setInstructorBio] = useState('');
  const [_instructorFullTime, setInstructorFullTime] = useState('');
  const [_instructorSupport, setInstructorSupport] = useState('');
  const [_instructorJobBand, setInstructorJobBand] = useState('');
  const [_instructorPercentage, setInstructorPercentage] = useState('');
  const [_instructorPaylocityId, setInstructorPaylocityId] = useState('');
  const [_instructorDepartmentId, setInstructorDepartmentId] = useState('');
  const [_instructorAutoAssignMin, setInstructorAutoAssignMin] = useState('');
  const [_instructorAutoAssignMax, setInstructorAutoAssignMax] = useState('');
  const [_instructorDegree, setInstructorDegree] = useState('');
  const [_instructorDegreeArray, setInstructorDegreeArray] = useState([]);
  const [_adminFirstName, setAdminFirstName] = useState('');
  const [_adminLastName, setAdminLastName] = useState('');
  const [_adminEmail, setAdminEmail] = useState('');
  const [_adminFullTime, setAdminFullTime] = useState('');
  const [_adminDepartment, setAdminDepartment] = useState('');
  const [_draggingItem, setDraggingItem] = useState(null);
  const partnerAgreements = useSelector((state) => state.observerPartnerAgreements);
  const columnNames = useSelector((state) => state.tableColumnNames);
  const instructors = useSelector((state) => state.instructors);
  const departments = useSelector((state) => state.departments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTableColumnNames(_tableName));
  }, [dispatch, _tableName]);

  useEffect(() => {
    dispatch(fetchObserverPartnerAgreements());
    dispatch(fetchDepartments());
  }, []);

  useEffect(() => {
    dispatch(fetchInstructors());
  }, [dispatch, _instructorId]);

  useEffect(() => {
    let instructor;
    if (Object.values(instructors).length > 0) {
      instructor = instructors.find((inst) => inst.id === _instructorId);
    }

    if (_instructorId === '') {
      _setInstructorValuesToEmptyString();
    }
    if (instructor !== undefined) {
      setInstructorTitle(instructor.title);
      setInstructorFirstName(instructor.first_name);
      setInstructorMiddleName(instructor.middle_name);
      setInstructorLastName(instructor.last_name);
      setInstructorSuffix(instructor.suffix);
      setInstructorEmail(instructor.email);
      setInstructorDisplayName(instructor.website_display_name);

      if (instructor.full_time === null) {
        setInstructorFullTime('');
      } else {
        setInstructorFullTime(instructor.full_time);
      }

      if (instructor.support === null) {
        setInstructorSupport('');
      } else {
        setInstructorSupport(instructor.support);
      }

      if (instructor.job_band_reference_id === null) {
        setInstructorJobBand('');
      } else {
        setInstructorJobBand(instructor.job_band_reference_id);
      }

      if (instructor.percentage === null) {
        setInstructorPercentage('');
      } else {
        setInstructorPercentage(instructor.percentage);
      }

      if (instructor.paylocity_id === null) {
        setInstructorPaylocityId('');
      } else {
        setInstructorPaylocityId(instructor.paylocity_id);
      }
    }
  }, [_instructorId, instructors]);

  const _returnColumnNameOptions = () => {
    return (columnNames.map((colName) => {return <MenuItem key={colName} value={colName}>{colName}</MenuItem>;}));
  };

  const _handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const _handleDragEnd = () => {
    setDraggingItem(null);
  };

  const _handleDragOver = (e) => {
    e.preventDefault();
  };

  const _handleDrop = (e, targetItem) => {
    const draggingItem = _draggingItem;
    const instructorDegrees = _instructorDegreeArray;
    if (!draggingItem) return;

    const currIndex = instructorDegrees.indexOf(draggingItem);
    const targetIndex = instructorDegrees.indexOf(targetItem);

    if (currIndex !== -1 && targetIndex !== -1) {
      instructorDegrees.splice(currIndex, 1);
      instructorDegrees.splice(targetIndex, 0, draggingItem);
      setInstructorDegreeArray(instructorDegrees);
    }

    setInstructorDegreeArray(_instructorDegreeArray.map((degree, index) => ({position: index + 1, value: degree.value})));
  };

  const addNewDegree = () => {
    let nextDegreePosition = 1;
    if (_instructorDegreeArray.length !== 0) {
      nextDegreePosition = Math.max(..._instructorDegreeArray.map((item) => item.position)) + 1;
    }
    _instructorDegreeArray.push({
      position: nextDegreePosition,
      value: _instructorDegree,
    });
  };

  const _deleteDegree = (e, degree) => {
    let newDegreeArray = _instructorDegreeArray;
    newDegreeArray = newDegreeArray.filter(function (filteredDegree) {
      return filteredDegree.value !== degree.value;
    });
    newDegreeArray = newDegreeArray.map((degree, index) => ({position: index + 1, value: degree.value}));
    setInstructorDegreeArray(newDegreeArray);
  };

  const _returnInstructorObject = () => {
    return {
      id: _instructorId,
      title: _instructorTitle,
      first_name: _instructorFirstName,
      middle_name: _instructorMiddleName,
      last_name: _instructorLastName,
      suffix: _instructorSuffix,
      email: _instructorEmail,
      website_display_name: _instructorDisplayName,
      bio: _instructorBio,
      degrees: _instructorDegreeArray,
      full_time: _instructorFullTime ? 1 : 0,
      support: _instructorSupport ? 1 : 0,
      job_band_reference_id: _instructorJobBand,
      percentage: _instructorPercentage,
      paylocity_id: _instructorPaylocityId,
      department_id: _instructorDepartmentId,
      monthly_min: _instructorAutoAssignMin,
      monthly_max: _instructorAutoAssignMax,
    };
  };

  const _returnAdminObject = () => {
    return {
      first_name: _adminFirstName,
      last_name: _adminLastName,
      email: _adminEmail,
      full_time: _adminFullTime,
      department: _adminDepartment,
    };
  };

  const _setInstructorValuesToEmptyString = () => {
    setInstructorId('');
    setInstructorTitle('');
    setInstructorMiddleName('');
    setInstructorSuffix('');
    setInstructorBio('');
    setInstructorFirstName('');
    setInstructorLastName('');
    setInstructorEmail('');
    setInstructorDegree('');
    setInstructorDegreeArray([]);
    setInstructorPaylocityId('');
    setInstructorFullTime('');
    setInstructorSupport('');
    setInstructorJobBand('');
    setInstructorPercentage('');
    setInstructorDisplayName('');
    setInstructorDepartmentId('');
    setInstructorAutoAssignMin('');
    setInstructorAutoAssignMax('');
  };

  const _setAdminValuesToEmptyString = () => {
    setAdminFirstName('');
    setAdminLastName('');
    setAdminEmail('');
    setAdminFullTime('');
    setAdminDepartment('');
  };

  return (
    <div style={styles.container}>
      <h3>Update database records</h3>
      <p>This only updates records in the given table. Any Cognito, Canvas, or other third party tool work (ex. creating a new cognito_id if you update a student's email) must be done manually.</p>
      <div style={styles.inputContainer}>
        <p>UPDATE</p>
        <FormControl>
          <InputLabel id='table_name'>Table</InputLabel>
          <Select
            htmlFor='table_name'
            label='Table'
            value={_tableName}
            onChange={(e) => setTableName(e.target.value)}
          >
            <MenuItem key='enrollments' value='enrollments'>enrollments</MenuItem>
            <MenuItem key='students' value='students'>students</MenuItem>
          </Select>
        </FormControl>
        <p>SET</p>
        <FormControl>
          <InputLabel id='column_name' required>Column Name</InputLabel>
          <Select
            htmlFor='column_name'
            label='Column Name'
            value={_columnName}
            onChange={(e) => setColumnName(e.target.value)}
            style={styles.formControl}
          >
            {_returnColumnNameOptions()}
          </Select>
        </FormControl>
        <p>=</p>
        <TextField
          label='Column value'
          variant='outlined'
          value={_columnValue}
          onChange={(e) => setColumnValue(e.target.value)}
          required={_isColumnValueRequired}
          disabled={_isNullValue || _isColumnCurrentTimestamp}
        />
        <p>WHERE ID =</p>
        <TextField
          label='Record ID'
          variant='outlined'
          value={_recordId}
          onChange={(e) => setRecordId(e.target.value)}
          required
        />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span>
            <Checkbox
              value={_isNullValue}
              onChange={(e) => {
                setIsNullValue(e.target.checked);
                setIsColumnValueRequired(!e.target.checked);
              }}
              required={false}
              disabled={_isColumnCurrentTimestamp}
            />
            Set Column Value as Null
          </span>
          <span>
            <Checkbox
              value={_isColumnCurrentTimestamp}
              onChange={(e) => {
                setIsColumnCurrentTimestamp(e.target.checked);
                setIsColumnValueRequired(!e.target.checked);
                setColumnValue(new Date().toISOString().slice(0, 19));
              }}
              required={false}
              disabled={_isNullValue}
            />
            Set Column Value to Current Timestamp
          </span>
        </div>
      </div>
      <div>
        <Button
          variant='contained'
          disabled={!_recordId}
          color='secondary'
          onClick={() => dispatch(updateDatabaseRecord(_tableName, _columnName, _columnValue, _recordId, _isNullValue))}
        >
          Update Record to DB
        </Button>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Add new Canvas section</h3>
        <div style={styles.canvasSectionContainer} >
          <TextField
            style={{marginRight: 8}}
            label='Canvas course ID'
            value={_canvasCourseId}
            onChange={(e) => setCanvasCourseId(e.target.value)}
            margin='dense'
            required
          />
          <Button
            variant='contained'
            disabled={!_canvasCourseId}
            color='secondary'
            onClick={() => dispatch(addNewSection(_canvasCourseId))}
          >
            Create Canvas Section
          </Button>
        </div>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Add New Place</h3>
        <div style={styles.inputContainer}>
          <p>INSERT INTO</p>
          <TextField
            disabled
            label='Table'
            value='places'
            variant='outlined'
            onChange={(e) => setTableName(e.target.value)}
            margin='dense'
          />
          <p>VALUES</p>
          <TextField
            fullWidth
            label='(title, email_to, contact_name_1, address, city, state, zip, address2, url, date_modified)'
            multiline
            value={_insertValues}
            onChange={(e) => setInsertValues(e.target.value)}
            margin='dense'
            variant='outlined'
            required
          />
        </div>
        <Button
          style={{marginTop: '10px'}}
          variant='contained'
          disabled={!_insertValues}
          color='secondary'
          onClick={() => dispatch(insertDatabaseRecord('places', _insertValues))}
        >
          Create New Place
        </Button>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Create Observer</h3>
        <div style={styles.observerContainer}>
          <div style={styles.inputContainer}>
            <FormControl>
              <InputLabel id='institution'>Institution</InputLabel>
              <Select
                htmlFor='institution'
                label='Institution'
                sx={{minWidth: '250px'}}
                value={_selectedInstitutionId}
                onChange={(e) => setSelectedInstitutionId(e.target.value)}
              >
                {Object.keys(partnerAgreements).length > 0 && partnerAgreements.map((institution) =>
                  <MenuItem key={institution.id} value={institution.id}>{institution.name}</MenuItem>,
                )}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='institution-agreement'>Institution Agreement</InputLabel>
              <Select
                htmlFor='institution-agreement'
                label='Institution Agreement'
                value={_institutionAgreement}
                sx={{minWidth: '250px'}}
                onChange={(e) => setInstitutionAgreement(e.target.value)}
                disabled={!_selectedInstitutionId}
              >
                {Object.keys(partnerAgreements).length > 0 && partnerAgreements.filter((institution) => institution.id === _selectedInstitutionId).map((filteredInstitution) =>
                  filteredInstitution.partner_agreements.map((agreement) => {
                    return (<MenuItem key={agreement.id} value={agreement.id}>{agreement.type}</MenuItem>);
                  }),
                )
                }
              </Select>
            </FormControl>
          </div>
          <div style={styles.inputContainer}>
            <TextField
              label='First Name'
              onChange={(e) => setObserverFirstName(e.target.value)}
            />
            <TextField
              label='Last Name'
              onChange={(e) => setObserverLastName(e.target.value)}
            />
            <TextField
              label='Observer Email'
              style={{minWidth: 300}}
              onChange={(e) => setObserverEmail(e.target.value)}
            />
          </div>
          <Button
            variant='contained'
            color='secondary'
            disabled={!_observerFirstName || !_observerLastName || !_observerEmail || !_institutionAgreement}
            onClick={() => {
              setObserverFirstName(null);
              setObserverLastName(null);
              setObserverEmail(null);
              setSelectedInstitutionId(null);
              setInstitutionAgreement(null);

              dispatch(createPartnerAgreementObserver(_institutionAgreement, {
                first_name: _observerFirstName,
                last_name: _observerLastName,
                email: _observerEmail,
              }));
            }}
            style={{width: 'fit-content'}}
          >
            Create Observer
          </Button>
        </div>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Create/Update Instructor</h3>
        <p>To Create: Fill out all fields marked with * and any other fields, press Create Instructor button</p>
        <p>To Update: Enter Instructor ID and any other fields to update, press Update Instructor button</p>
        <div style={styles.instructorContainer}>
          <FormControl>
            <InputLabel id='instructor-id'>Instructor ID</InputLabel>
            <Select
              htmlFor='instructor-id'
              label='Instructor ID'
              sx={{maxWidth: '30%'}}
              value={_instructorId}
              onChange={(e) => {
                setInstructorId(e.target.value);
                setInstructorBio('');
                setInstructorDegreeArray([]);
              }}
            >
              {instructors.length > 0 && instructors.map((instructor) =>
                <MenuItem key={instructor.id} value={instructor.id}>{instructor.first_name} {instructor.last_name}</MenuItem>,
              )}
              <MenuItem key={''} value={''}>No Instructor</MenuItem>
            </Select>
          </FormControl>
          <div style={styles.inputContainer}>
            <FormControl>
              <InputLabel id='title'>*Title</InputLabel>
              <Select
                htmlFor='title'
                label='Title'
                sx={{minWidth: '150px', marginRight: '10px'}}
                value={_instructorTitle}
                onChange={(e) => setInstructorTitle(e.target.value)}
              >
                <MenuItem key='Professor' value='Professor'>Professor</MenuItem>
                <MenuItem key='Dr.' value='Dr.'>Dr.</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label='*First Name'
              value={_instructorFirstName}
              onChange={(e) => setInstructorFirstName(e.target.value)}
            />
            <TextField
              label='Middle Name'
              value={_instructorMiddleName}
              onChange={(e) => setInstructorMiddleName(e.target.value)}
            />
            <TextField
              label='*Last Name'
              value={_instructorLastName}
              onChange={(e) => setInstructorLastName(e.target.value)}
            />
            <TextField
              label='Suffix'
              style={{width: 75}}
              value={_instructorSuffix}
              onChange={(e) => setInstructorSuffix(e.target.value)}
            />
            <TextField
              label='*Email'
              style={{width: 450}}
              value={_instructorEmail}
              onChange={(e) => setInstructorEmail(e.target.value)}
            />
          </div>
          <div style={styles.inputContainer}>
            <FormControlLabel
              required
              label='Full Time'
              control={
                <Switch
                  checked={_instructorFullTime}
                  onChange={(e) => setInstructorFullTime(!_instructorFullTime)}
                />
              }
            />
            <FormControlLabel
              required
              label='Support'
              control={
                <Switch
                  checked={_instructorSupport}
                  onChange={(e) => setInstructorSupport(!_instructorSupport)}
                />
              }
            />
            <FormControl>
              <InputLabel id='job-band-id'>Job Band ID</InputLabel>
              <Select
                htmlFor='job-band-id'
                label='Job Band ID'
                sx={{width: '180px'}}
                value={_instructorJobBand}
                onChange={(e) => setInstructorJobBand(e.target.value)}
              >
                <MenuItem key={null} value={null}>Null</MenuItem>
                <MenuItem key='2' value='2'>2</MenuItem>
                <MenuItem key='3' value='3'>3</MenuItem>
                <MenuItem key='4' value='4'>4</MenuItem>
                <MenuItem key='5' value='5'>5</MenuItem>
              </Select>
            </FormControl>
            <TextField
              style={{marginLeft: '10px'}}
              label='Percentage'
              value={_instructorPercentage}
              onChange={(e) => setInstructorPercentage(e.target.value)}
            />
            <TextField
              label='Paylocity ID'
              value={_instructorPaylocityId}
              onChange={(e) => setInstructorPaylocityId(e.target.value)}
            />
            <TextField
              label='*Website Display Name'
              style={{width: 300}}
              value={_instructorDisplayName}
              onChange={(e) => setInstructorDisplayName(e.target.value)}
            />
          </div>
          {!_instructorId &&
            <div style={styles.metadataContainer}>
              <div style={styles.inputContainer}>
                <p>Auto Assign Data:</p>
                <FormControl>
                  <InputLabel id='department'>Department</InputLabel>
                  <Select
                    label='Department'
                    sx={{minWidth: '250px'}}
                    value={_instructorDepartmentId}
                    onChange={(e) => setInstructorDepartmentId(e.target.value)}
                  >
                    {Object.keys(departments).length > 0 && departments.map((dep) =>
                      <MenuItem key={dep.id} value={dep.id}>{dep.name}</MenuItem>,
                    )}
                  </Select>
                </FormControl>
                <TextField
                  type='number'
                  label='Min'
                  value={_instructorAutoAssignMin}
                  onChange={(e) => setInstructorAutoAssignMin(e.target.value)}
                />
                <TextField
                  type='number'
                  label='Max (-1 for uncapped)'
                  value={_instructorAutoAssignMax}
                  onChange={(e) => setInstructorAutoAssignMax(e.target.value)}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  label='Instructor Bio'
                  style={{width: 975}}
                  multiline
                  value={_instructorBio}
                  onChange={(e) => setInstructorBio(e.target.value)}
                />
              </div>
              <div style={styles.degreeInputContainer}>
                <TextField
                  label='Instructor Degree (level, major, year, university)'
                  style={{width: 450}}
                  value={_instructorDegree}
                  onChange={(e) => setInstructorDegree(e.target.value)}
                />
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={!_instructorDegree}
                  onClick={() => {
                    setInstructorDegree('');
                    addNewDegree();
                  }}
                >
                  Add Degree
                </Button>
              </div>
              <div style={styles.degreeDisplayContainer}>
                <div>
                  <h6>Instructor Degrees Taken:</h6>
                  {_instructorDegreeArray.map((degree, index) =>
                    <div
                      style={styles.degreeList}
                      key={degree.position}
                      className={`item ${degree === _draggingItem ? 'dragging' : ''}`}
                      draggable='true'
                      onDragStart={(e) => _handleDragStart(e, degree)}
                      onDragEnd={_handleDragEnd}
                      onDragOver={_handleDragOver}
                      onDrop={(e) => _handleDrop(e, degree)}
                    >
                      <div style={styles.degreeCard}>
                        <span>{degree.position}.</span>
                        <span>{degree.value}</span>
                        <ReorderIcon />
                        <DeleteForeverIcon
                          onClick={(e) => _deleteDegree(e, degree)}
                        ></DeleteForeverIcon>
                      </div>
                    </div>,
                  )}
                </div>
              </div>
            </div>
          }
          <div style={styles.buttonContainer}>
            <Button
              variant='contained'
              color='secondary'
              disabled={(!_instructorTitle || !_instructorFirstName || !_instructorLastName || !_instructorEmail || !_instructorDisplayName) || _instructorId}
              onClick={() => {
                _setInstructorValuesToEmptyString();
                dispatch(createInstructor(_returnInstructorObject()));
              }}
              style={{width: 'fit-content'}}
            >
              Create Instructor
            </Button>
            <Button
              variant='contained'
              color='secondary'
              disabled={!_instructorId}
              onClick={() => {
                _setInstructorValuesToEmptyString();
                dispatch(updateInstructor(_returnInstructorObject()));
              }}
              style={{width: 'fit-content'}}
            >
              Update Instructor
            </Button>
          </div>
        </div>
      </div>
      <span style={styles.divisionLine}></span>
      <div style={{marginTop: 50}}>
        <h3>Create Admin</h3>
        <div style={styles.instructorContainer}>
          <div style={styles.inputContainer}>
            <TextField
              label='First Name'
              value={_adminFirstName}
              onChange={(e) => setAdminFirstName(e.target.value)}
            />
            <TextField
              label='Last Name'
              value={_adminLastName}
              onChange={(e) => setAdminLastName(e.target.value)}
            />
            <TextField
              label='Email'
              style={{width: 450}}
              value={_adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
            />
            <FormControl>
              <InputLabel id='schedule'>Schedule</InputLabel>
              <Select
                htmlFor='schedule'
                label='Schedule'
                sx={{width: '150px'}}
                value={_adminFullTime}
                onChange={(e) => setAdminFullTime(e.target.value)}
              >
                <MenuItem key='Full Time' value='1'>Full Time</MenuItem>
                <MenuItem key='Part Time' value='0'>Part Time</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='department'>Department</InputLabel>
              <Select
                label='Department'
                htmlFor='department'
                sx={{width: '150px'}}
                value={_adminDepartment}
                onChange={(e) => setAdminDepartment(e.target.value)}
              >
                <MenuItem key='Academics' value='Academics'>Academics</MenuItem>
                <MenuItem key='Administration' value='Administration'>Administration</MenuItem>
                <MenuItem key='Engineering' value='Engineering'>Engineering</MenuItem>
                <MenuItem key='Finance' value='Finance'>Finance</MenuItem>
                <MenuItem key='IT' value='IT'>IT</MenuItem>
                <MenuItem key='Marketing' value='Marketing'>Marketing</MenuItem>
                <MenuItem key='Multimedia' value='Multimedia'>Multimedia</MenuItem>
                <MenuItem key='School Relations' value='School Relations'>School Relations</MenuItem>
                <MenuItem key='Student Services' value='Student Services'>Student Services</MenuItem>
                <MenuItem key='Faculty Dept Chair' value='Faculty Dept Chair'>Faculty Dept Chair</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={styles.buttonContainer}>
            <Button
              variant='contained'
              color='secondary'
              disabled={!_adminFirstName || !_adminLastName || !_adminEmail || !_adminFullTime || !_adminDepartment}
              onClick={() => {
                dispatch(createAdmin(_returnAdminObject(),
                  _setAdminValuesToEmptyString,
                ));
              }}
              style={{width: 'fit-content'}}
            >
              Create Admin
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatabaseTools;
