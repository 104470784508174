/*
 * Stevie Michalik
 * 2024-06-13
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  fetchAcademicScholarshipReport,
  getAllCampuses,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import AcademicScholarshipTableRow from './AcademicScholarshipTableRow';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';
import styles from './styles';

const scholarshipStatuses = [
  'created',
  'notified',
  'applied',
  'rejected',
  'approved',
];

const AcademicScholarships = () => {
  const [_rowsPerPage, setRowsPerPage] = useState(10);
  const [_pageNum, setPageNum] = useState(0);
  const [_searchTerms, setSearchTerms] = useState('');
  const [_selectedStatusType, setSelectedStatusType] = useState(['created', 'notified', 'applied']);
  const [_notifyOpen, setNotifyOpen] = useState(false);
  const [_remindOpen, setRemindOpen] = useState(false);
  const [_acceptOpen, setAcceptOpen] = useState(false);
  const [_resetOpen, setResetOpen] = useState(false);
  const [_rejectOpen, setRejectOpen] = useState(false);

  const scholarships = useSelector((state) => state.academicScholarships);
  const campuses = useSelector((state) => state.allCampuses);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAcademicScholarshipReport());
    dispatch(getAllCampuses());
  }, [dispatch]);

  const _renderScholarships = () => {
    if (!Object.values(scholarships).length) {
      return (<p>loading.....</p>);
    }

    const searchTermsSplit = _searchTerms.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));

    const filteredScholarships = Object.values(scholarships).filter((scholarship) => {
      return _selectedStatusType.some((statusType) => {
        return scholarship.scholarship_status === statusType;
      });
    }).filter((scholarship) => {
      if (_searchTerms === '') {
        return true;
      }

      return searchTermsSplit.some((groupOfSearchTerms) => {
        return groupOfSearchTerms.every((searchTerm) => {
          return Object.keys(scholarship).some((scholarshipAttribute) => {
            return `${scholarship[scholarshipAttribute]}`.toLowerCase().includes(searchTerm);
          });
        });
      });
    });

    const sortedScholarships = filteredScholarships.sort((s1, s2) => {
      return dayjs(s2.most_recent_enrollment_conclusion) - dayjs(s1.most_recent_enrollment_conclusion);
    });

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Scholarship Status</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Preferred School</TableCell>
              <TableCell>Credits Taken</TableCell>
              <TableCell>Last Enrollment Conclusion</TableCell>
              <TableCell>Latest Status Change</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(sortedScholarships).slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((scholarship) => {
              return <AcademicScholarshipTableRow key={scholarship.id} plScholarship={scholarship} plCampuses={campuses}/>;
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={sortedScholarships.length}
          rowsPerPage={_rowsPerPage}
          page={_pageNum}
          slotProps={{
            previousButton: {'aria-label': 'Previous Page'},
            nextButton: {'aria-label': 'Next Page'},
          }}
          onPageChange={(event, _pageNum) => setPageNum(_pageNum)}
          onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
        />
      </TableContainer>
    );
  };

  return (
    <div>
      <h1>Academic Scholarships Page</h1>
      <p>This feature allows for management of all students who are eligible for the Portage Learning Academic Scholarship</p>
      <p>
        From this page, you can see which students are scholarship eligible, information for the student, see the status of the student,
        notify the student of their eligibility, show they have applied, and either accept or reject the student application.
      </p>
      <div style={styles.searchAndSelectContainer}>
        <TextField
          label='Search'
          variant='standard'
          onChange={(e) => {
            setSearchTerms(e.target.value);
            setPageNum(0);
          }}
          helperText='Student Name, email, school, etc.'
        />
        <FormControl>
          <Select
            variant='standard'
            defaultValue={_selectedStatusType}
            multiple
            onChange={(e) => {
              const {
                target: {value},
              } = e;
              setSelectedStatusType(
                typeof value === 'string' ? value.split(',') : value,
              );
              setPageNum(0);
            }}
            renderValue={(selected) => selected.join(', ')}
          >
            {scholarshipStatuses.map((status) => {
              return (
                <MenuItem value={status} key={status}>
                  <Checkbox checked={_selectedStatusType.indexOf(status) > -1} />
                  <ListItemText primary={status} />
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Scholarship Status</FormHelperText>
        </FormControl>
        <div style={styles.buttonsRow}>
          <div>
            <p><strong>Information about buttons:  </strong></p>
          </div>
          <div>
            <Button
              startIcon={<InfoIcon />}
              onClick={() => setNotifyOpen(true)}
              color='secondary'
              size='large'
            >
              Notify
            </Button>
            <Modal
              open={_notifyOpen}
              onClose={() => setNotifyOpen(false)}
            >
              <Box style={styles.modalBox}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Notify Button
                </Typography>
                <Typography id='modal-modal-description' sx={{mt: 2}}>
                  <ul>
                    <li>Sends an email to the specified student informing of scholarship eligibility</li>
                    <li>Marks the student with Scholarship Status &quot;notified&quot; in this table.</li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </div>
          <div>
            <Button
              startIcon={<InfoIcon/>}
              onClick={() => setRemindOpen(true)}
              color='secondary'
              size='large'
            >
              Remind
            </Button>
            <Modal
              open={_remindOpen}
              onClose={() => setRemindOpen(false)}
            >
              <Box style={styles.modalBox}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Remind Button
                </Typography>
                <Typography id='modal-modal-description' sx={{mt: 2}}>
                  <ul>
                    <li>Sends email to student reminding them to apply for scholarship.</li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </div>
          <div>
            <Button
              startIcon={<InfoIcon />}
              onClick={() => setAcceptOpen(true)}
              color='secondary'
              size='large'
            >
              Accept
            </Button>
            <Modal
              open={_acceptOpen}
              onClose={() => setAcceptOpen(false)}
            >
              <Box style={styles.modalBox}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Accept Button
                </Typography>
                <Typography id='modal-modal-description' sx={{mt: 2}}>
                  <ul>
                    <li>Sends an email to the specified student informing of scholarship reception</li>
                    <li>Marks the student with Scholarship Status &quot;accepted&quot; in this table.</li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </div>
          <div>
            <Button
              startIcon={<InfoIcon />}
              onClick={() => setResetOpen(true)}
              color='secondary'
              size='large'
            >
              Reset
            </Button>
            <Modal
              open={_resetOpen}
              onClose={() => setResetOpen(false)}
            >
              <Box style={styles.modalBox}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Reset Button
                </Typography>
                <Typography id='modal-modal-description' sx={{mt: 2}}>
                  <ul>
                    <li>Marks the student with Scholarship Status &quot;applied&quot; in this table.</li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </div>
          <div>
            <Button
              startIcon={<InfoIcon/>}
              onClick={() => setRejectOpen(true)}
              color='primary'
              size='large'
            >
              Reject
            </Button>
            <Modal
              open={_rejectOpen}
              onClose={() => setRejectOpen(false)}
            >
              <Box style={styles.modalBox}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Reject Button
                </Typography>
                <Typography id='modal-modal-description' sx={{mt: 2}}>
                  <ul>
                    <li>Sends an email to the specified student informing them that their scholarship application has been rejected.</li>
                    <li>Marks the student with Scholarship Status &quot;rejected&quot; in this table.</li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
      {_renderScholarships()}
    </div>
  );
};

export default AcademicScholarships;
