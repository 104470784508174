/*
 * Ryan O'Dowd
 * 2019-03-19
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
// @TODO: clean up
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  addPerm,
  disableAdmin,
  fetchAdmins,
  masqueradeAdmin,
  removePerm,
  setPerms,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Alert from '@mui/material/Alert';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import DramaMasksIcon from 'mdi-react/DramaMasksIcon';
import Globals from '../../Globals';
import Highlighter from 'react-highlight-words';
import PlusMinusIcon from 'mdi-react/PlusMinusIcon';
import styles from './styles';

const DEPARTMENT_COLORS = {
  Academics: '#bdccde',
  Administration: '#91abc8',
  Engineering: '#bdccde',
  Finance: '#91abc8',
  IT: '#bdccde',
  Marketing: '#91abc8',
  Multimedia: '#bdccde',
  PIE: '#91abc8',
  'Student Services': '#bdccde',
};

const REQUIRED_ERROR = 'required';

const Admins = () => {
  const [_pageNum, setPageNum] = useState(0);
  const [_rowsPerPage, setRowsPerPage] = useState(50);
  const [_searchText, setSearchText] = useState('');
  const [_addRemovePermDialog, setAddRemovePermDialog] = useState(false);
  const [_permNameToCreate, setPermNameToCreate] = useState('');
  const [_permNameToDelete, setPermNameToDelete] = useState('');
  const [_addRemovePermError, setAddRemovePermError] = useState(false);
  const admins = useSelector((state) => state.admins);
  const loadingStates = useSelector((state) => state.loadingStates);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdmins());
  }, []);

  const humanReadableColumnName = (unformattedColumnName) => {
    return `${unformattedColumnName.charAt(0).toUpperCase()}${unformattedColumnName.replace(/_/g, ' ').slice(1)}`;
  };

  const renderPermsHeaderCells = () => {
    return Object.keys(Object.values(admins)[0].perms).map((columnName) => {
      return (
        <TableCell
          key={columnName}
          align='right'
          style={styles.headerCell}
        >
          <span style={styles.columnHeaderTextWrapper}>
            <span style={styles.columnHeaderText}>
              {humanReadableColumnName(columnName)}
            </span>
          </span>
        </TableCell>
      );
    });
  };

  const renderPermsBodyCells = (admin) => {
    return Object.keys(admin.perms || {}).map((perm) => {
      const cellContent = (admin.perms[perm] === 1
        ? (
          <CheckIcon
            key={perm}
            color={Globals.colors.accent}
            size={14}
            style={styles.permIcon}
            onClick={() => dispatch(setPerms(admin.id, {...admin.perms, [perm]: +!admin.perms[perm]}))}
          />
        ) : (
          <CloseIcon
            key={perm}
            color={Globals.colors.error}
            size={14}
            style={styles.permIcon}
            onClick={() => dispatch(setPerms(admin.id, {...admin.perms, [perm]: +!admin.perms[perm]}))}
          />
        )
      );

      return (
        <TableCell
          key={perm}
          align='right'
          style={styles.permCell}
        >
          {cellContent}
        </TableCell>
      );
    });
  };

  const resetVars = () => {
    setPermNameToCreate('');
    setPermNameToDelete('');
    setAddRemovePermDialog(!_addRemovePermDialog);
  };

  if (Object.values(Object.keys(admins)).length === 0) {
    return <CircularProgress />;
  }

  const searchTerms = _searchText.split(' ').map((text) => text.toLowerCase());
  const filteredAdmins = Object.values(admins).filter((admin) => {
    return Object.values(admin).some((adminAttribute) => {
      return searchTerms.every((searchTerm) => `${adminAttribute}`.toLowerCase().includes(searchTerm));
    });
  }).sort((a, b) => a.last_name.localeCompare(b.last_name));
  const emptyRows = _rowsPerPage - Math.min(_rowsPerPage, filteredAdmins.length - _pageNum * _rowsPerPage);

  return (
    <div style={styles.container}>
      <h3>Admins</h3>
      <Paper>
        <div style={styles.controlsContainer}>
          <TextField
            style={styles.textField}
            label='Search'
            value={_searchText}
            onChange={(e) => setSearchText(e.target.value)}
            margin='dense'
          />
          <div>
            <Tooltip title='Add/Remove Admin Perm'>
              <IconButton
                onClick={() => setAddRemovePermDialog(!_addRemovePermDialog)}
                size='large'
              >
                <PlusMinusIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={styles.tableWrapper}>
          <Table style={styles.table} stickyHeader={true} size='small'>
            {/* @TODO: make columns sortable? */}
            <TableHead style={styles.tableHeader}>
              <TableRow>
                <TableCell
                  align='right'
                  style={styles.firstColumn}
                >
                  Administrator
                </TableCell>
                {!!admins && renderPermsHeaderCells()}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAdmins.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((admin) => {
                return (
                  <React.Fragment key={admin.username}>
                    <TableRow
                      hover
                      tabIndex={-1}
                      style={admin.disabled ? styles.disabledAdminPerms : {}}
                    >
                      <TableCell
                        align='right'
                        style={{
                          ...styles.firstColumn,
                          backgroundColor: DEPARTMENT_COLORS[admin.department],
                          ...(admin.disabled ? styles.disabledAdminNameCell : {}),
                        }}
                      >
                        <span style={styles.masqueradeAndNameWrapper}>
                          <span>
                            <Tooltip title={`${admin.email}: This masquerades on the front-end only and will reset after a page reload.`}>
                              <span>
                                <DramaMasksIcon
                                  style={styles.pointer}
                                  size={18}
                                  onClick={() => dispatch(masqueradeAdmin(admin))}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title={`${admin.email}: Disable/Enable this admin`}>
                              <span>
                                <Switch
                                  onChange={() => dispatch(disableAdmin(admin))}
                                  checked={!admin.disabled}
                                />
                              </span>
                            </Tooltip>
                          </span>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={_searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={`${admin.first_name} ${admin.last_name}`}
                          />
                        </span>
                      </TableCell>
                      {renderPermsBodyCells(admin)}
                    </TableRow>
                  </React.Fragment>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>{/* @TODO: magic number */}
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={filteredAdmins.length}
          rowsPerPage={_rowsPerPage}
          page={_pageNum}
          slotProps={{
            previousButton: {'aria-label': 'Previous Page'},
            nextButton: {'aria-label': 'Next Page'},
          }}
          onPageChange={(event, pageNum) => setPageNum(pageNum)}
          onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
        />
      </Paper>
      <Dialog
        open={_addRemovePermDialog}
        onClose={() => resetVars()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>Add/Remove Perm</DialogTitle>
        <DialogContent>
          <Alert severity='warning'><p>Perm names should follow <b>snake_case</b> naming convention and be lower case.</p></Alert>
          <div style={styles.addRemovePermRow}>
            <FormControl>
              <TextField
                placeholder='Perm Name'
                style={styles.addRemovePerm}
                error={!!_addRemovePermError}
                onChange={(e) => setPermNameToCreate(e.target.value)}
              />
            </FormControl>
            <div style={styles.spacer}></div>
            <Button
              color='secondary'
              disabled={loadingStates.addPerm}
              variant='contained'
              onClick={() => {
                // @TODO: Should we validate snake case naming convention
                if (!_permNameToCreate) {
                  setAddRemovePermError(REQUIRED_ERROR);
                  return;
                }
                dispatch(addPerm(_permNameToCreate.toLowerCase(), () => resetVars()));
              }}
            >
              Add Perm
            </Button>
          </div>
          <div style={styles.addRemovePermRow}>
            <FormControl>
              <InputLabel id='perm-name-to-delete-label'>Perm Name</InputLabel>
              <Select
                labelId='perm-name-to-delete-label'
                id='perm-name-to-delete-id'
                style={styles.addRemovePerm}
                value={_permNameToDelete}
                onChange={(e) => setPermNameToDelete(e.target.value)}
              >
                <MenuItem value='' disabled>Perm Name</MenuItem>
                {Object.keys(Object.values(admins)[0].perms).map((columnName) =>
                  <MenuItem key={columnName} value={columnName}>{humanReadableColumnName(columnName)}</MenuItem>,
                )}
              </Select>
            </FormControl>
            <div style={styles.spacer}></div>
            <Button
              variant='contained'
              color='primary'
              disabled={loadingStates.removePerm}
              onClick={() => {
                if (!_permNameToDelete) {
                  setAddRemovePermError(REQUIRED_ERROR);
                  return;
                }
                dispatch(removePerm(_permNameToDelete, () => resetVars()));
              }}
              style={{maxHeight: 40}}
            >
              Remove Perm
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddRemovePermDialog(!_addRemovePermDialog)}
            color='secondary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Admins;
