/*
 * Ryan O'Dowd
 * 2019-01-22
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  approveRefund,
  fetchRefunds,
  updateRefund,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import EditableTableCell from './EditableTableCell';
import Highlighter from 'react-highlight-words';
import {
  REFUNDS_READ_ONLY,
} from '../../Globals';
import dayjs from 'dayjs';
import {
  formatNumberAsUScurrency,
} from '../../utilities';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './styles';

dayjs.extend(localizedFormat);

const Refunds = () => {
  const [_searchText, setSearchText] = useState('');
  const [_pageNum, setPageNum] = useState(0);
  const [_rejectDialog, setRejectDialog] = useState(false);
  const [_rejectId, setRejectId] = useState(0);
  const [_rejectNotes, setRejectNotes] = useState('');
  const [_rowsPerPage, setRowsPerPage] = useState(10);
  const [_sortKey, setSortKey] = useState('created_at');
  const [_sortAsc, setSortAsc] = useState(false);
  const loadingStates = useSelector((state) => state.loadingStates);
  const refunds = useSelector((state) => Object.values(state.refunds));
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRefunds());
  }, []);

  const searchTerms = _searchText.split(' ').map((text) => text.toLowerCase());
  const filterRejected = !!Object.keys(refunds).length && refunds.filter((refund) => refund.status === 'open');
  const filteredData = filterRejected && filterRejected.filter((refund) => {
    return searchTerms.every((searchTerm) => {
      return Object.keys(refund).some((fileAttribute) => {
        return `${refund[fileAttribute]}`.toLowerCase().includes(searchTerm);
      });
    });
  });
  const emptyRows = _rowsPerPage - Math.min(_rowsPerPage, filteredData.length - _pageNum * _rowsPerPage);

  return (
    <div style={styles.container}>
      <h3>Refunds</h3>
      <Paper style={styles.tableWrapper}>
        <Toolbar style={styles.tableToolbar}>
          <div style={styles.leftItems}>
            {/* @TODO: search bar...needs to work on all fields, not just visible ones. but if not visible, need to show waht it's matching against...also have specific search bars for each column */}
            <TextField
              style={styles.textField}
              label='Search'
              value={_searchText}
              onChange={(e) => setSearchText(e.target.value)}
              margin='dense'
            />
          </div>
        </Toolbar>
        <div>
          <Table aria-labelledby='tableTitle'>
            {/* @TODO: make columns sortable? */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={_sortKey === 'created_at'}
                    direction={_sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      const SORT_KEY = 'created_at';
                      setSortKey(SORT_KEY);
                      setSortAsc(_sortKey === SORT_KEY ? !_sortAsc : true);
                    }}
                  >
                    Date requested
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={_sortKey === 'student_name'}
                    direction={_sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      const SORT_KEY = 'student_name';
                      setSortKey(SORT_KEY);
                      setSortAsc(_sortKey === SORT_KEY ? !_sortAsc : true);
                    }}
                  >
                    Student name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Student Id</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Transaction total</TableCell>
                <TableCell>Course total</TableCell>
                <TableCell>Amount to refund</TableCell>
                <TableCell>Refund Reason</TableCell>
                <TableCell>Notes</TableCell>
                {!admin.perms[REFUNDS_READ_ONLY] &&
                  <TableCell>Approval</TableCell>
                }
                {!admin.perms[REFUNDS_READ_ONLY] &&
                  <TableCell>Reject</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData && filteredData.sort((a, b) => {
                let valueOfA = a[_sortKey];
                let valueOfB = b[_sortKey];
                if (typeof valueOfA === 'string') {
                  valueOfA = dayjs(valueOfA.toLowerCase().trim());
                  valueOfB = dayjs(valueOfB.toLowerCase().trim());
                }
                if (_sortAsc) {
                  return valueOfA > valueOfB ? 1 : -1;
                }
                return valueOfA < valueOfB ? 1 : -1;
              }).slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).sort().map((refund) => {
                return (
                  <React.Fragment key={refund.id}>
                    <TableRow
                      hover
                      tabIndex={-1}
                    >
                      <TableCell>{dayjs(refund.created_at).format('ddd M/D/YY, LT')}</TableCell>
                      <TableCell>
                        <Highlighter
                          highlightStyle={styles.highlightedText}
                          searchWords={_searchText.split(' ')}
                          autoEscape={true}
                          textToHighlight={refund.student_name}
                        />
                      </TableCell>
                      <TableCell><a href={`/students/${refund.student_id}`}>{refund.student_id}</a></TableCell>
                      <TableCell>{refund.course.toUpperCase()}</TableCell>
                      <TableCell>{formatNumberAsUScurrency(refund.transaction_total)}</TableCell>
                      <TableCell>{formatNumberAsUScurrency(refund.course_total)}</TableCell>
                      <EditableTableCell
                        value={`${refund.refund_amount}`}
                        updateValue={(value) => dispatch(updateRefund(refund.id, value, false, null))}
                        formatAsCurrency={true}
                        readOnly={Boolean(admin.perms[REFUNDS_READ_ONLY])}
                      />
                      <TableCell>{refund.reason}</TableCell>
                      <TableCell>{refund.notes}</TableCell>
                      {!admin.perms[REFUNDS_READ_ONLY] &&
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            disabled={!refund.refund_amount || loadingStates.approveRefund || refund.status === 'rejected'}
                            onClick={() => dispatch(approveRefund(refund.id))}
                          >
                            Approve
                          </Button>
                        </TableCell>
                      }
                      {!admin.perms[REFUNDS_READ_ONLY] &&
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            disabled={refund.status === 'rejected'}
                            onClick={() => {
                              setRejectDialog(!_rejectDialog);
                              setRejectId(refund.id);
                            }}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      }
                    </TableRow>
                  </React.Fragment>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}> {/* @TODO: magic number */}
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {_rejectDialog &&
            <Dialog
              open={true}
              onClose={() => setRejectDialog(!_rejectDialog)}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle>Rejection Reason</DialogTitle>
              <DialogContent>
                <div>
                  <p>Please enter the reason for rejection:</p>
                  <TextField
                    style={styles.rejectionText}
                    label='Reason'
                    variant='outlined'
                    value={_rejectNotes}
                    onChange={(e) => setRejectNotes(e.target.value)}
                  />
                </div>
                <DialogActions>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      dispatch(updateRefund(_rejectId, null, _rejectDialog, _rejectNotes));
                      setRejectDialog(!_rejectDialog);
                    }}
                  >
                    Reject refund
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>}
        </div>
        {/* @TODO: update pagination props */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={_rowsPerPage}
          page={_pageNum}
          slotProps={{
            previousButton: {'aria-label': 'Previous Page'},
            nextButton: {'aria-label': 'Next Page'},
          }}
          onPageChange={(event, pageNum) => setPageNum(pageNum)}
          onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
        />
      </Paper>
    </div>
  );
};

export default Refunds;
