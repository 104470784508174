/*
 * Ally Zernick
 * 2022-10-20
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
    color: Globals.colors.light,
  },
  addStudentToExportDiv: {
    marginTop: 75,
  },
  editIcon: {
    right: 44,
    bottom: 10,
  },
  checkboxes: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  datePicker: {
    display: 'none',
  },
  autocompleteEditTerm: {
    width: 150,
  },
  lineSeparator: {
    width: '80%',
    height: 2.8,
    backgroundColor: 'gray',
    marginBottom: 75,
    marginTop: 75,
  },
  studentIdDiv: {
    marginBottom: 75,
  },
  selectOptions: {
    marginRight: 15,
    marginLeft: 15,
  },
  DisplayReportDatabuttonStyle: {
    marginTop: 8,
  },
  formControlSelectSchool: {
    width: '40%',
  },
  formControlSelectSecondary: {
    width: '17%',
  },
  selectedStudentRow: {
    backgroundColor: Globals.colors.lightGray,
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12,
    paddingBottom: 16,
  },
  studentRow: {
    cursor: 'pointer',
  },
  actionOptions: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
  },
  termSelect: {
    width: 'fit-content',
    minWidth: '300px',
    color: 'black',
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    marginRight: 8,
  },
  stateSelectField: {
    width: 80,
    marginRight: 8,
    marginTop: 21,
  },
  statusWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 4,
    height: '100%',
    color: Globals.colors.lightGray,
  },
  courseHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  courseName: {
    color: Globals.colors.accent,
  },
  courseInstructor: {
    color: Globals.colors.primary,
    fontSize: 12,
  },
  panelDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  muiFormControlLabelCustom: {
    marginRight: 8,
  },
  exportButton: {
    marginTop: 8,
  },
  idEntry: {
    marginRight: 8,
  },
  buttonStyle: {
    marginTop: 8,
    marginRight: 8,
    marginLeft: 15,
  },
  dropDowns: {
    paddingRight: 8,
    marginBottom: 75,
  },
  smallPaper: {
    marginBottom: 10,
    padding: 5,
  },
  invisible: {
    backgroundColor: '#CCC',
  },
};

export default styles;
